<template>
  <v-card color="#273142">
    <v-card-title color="#dbd7d779">
      <h3 class="mx-auto">Login</h3>
    </v-card-title>
    <v-divider class="mt-n1"></v-divider>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="card-body">
        <v-text-field
          v-model="email"
          label="Email"
          :rules="emailRules"
          :loading="loading"
          :error-messages="error.email"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          label="Password"
          :rules="passwordRules"
          required
          :loading="loading"
          :error-messages="error.password"
          :type="show ? 'text' : 'password'"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-3"
          width="30%"
          shaped
          color="success"
          type="submit"
          :loading="loading"
          @click.prevent="login"
        >
          Login
        </v-btn>

        <v-col>
          <router-link to="/auth/forgot-password" style="cursor:pointer;">
            Forgot Password?</router-link
          >
          <br />
          <router-link to="/auth/register" style="cursor:pointer;">
            Create new Account</router-link
          >
        </v-col>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { Base64 } from 'js-base64';
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      show: false,
      valid: false,
      email: "",
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      password: "",
      passwordRules: [v => !!v || "password is required"],
      loading: false,
      error: {}
    };
  },
  computed: {
    ...mapGetters(["getErrors", 'getOrderObject'])
  },
  watch: {
    getErrors(val) {
      this.error = val;
    }
  },
  methods: {
    ...mapActions(["performPostActions"]),
    ...mapMutations(["setAccessToken", "setRefreshToken", 'setSession']),
    async login() {
      const payload = {
        email: this.email,
        password: this.password
      };

      const fullPayload = {
        params: payload,
        endpoint: "/login"
      };
      const response = await this.performPostActions(fullPayload);
      console.log(response);
        const token = response.token;
        const refreshToken = response.refreshToken;
        const partsOfToken = token.split('.');
        const middleString = Base64.decode(partsOfToken[1]);
        const session  = JSON.parse(middleString)
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("refreshToken", refreshToken);
        this.setAccessToken(token);
        this.setRefreshToken(refreshToken);
        console.log(session);
        if (session.data.user_type === 1) {
          if (this.getOrderObject) {
            this.$router.push("/order/draft");
            return;
          }
          this.$router.push("/dashboard/orders/open");
        }
        if (session.data.user_type === 2) {
          this.$router.push('/writer')
        }
    }
  }
};
</script>

<style scoped>
.ant {
  background: #273142;
}
.card-header {
  height: 70px;
  border-bottom: 2px solid #dbd7d779;
}
</style>
